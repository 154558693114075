import {
    createMultiStyleConfigHelpers,
    defineStyle,
    theme,
} from "@chakra-ui/react";
import { inputAnatomy } from "@chakra-ui/anatomy";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    field: {
        fontSize: "sm",
    },
});

/* variants */
const filterInput = definePartsStyle({
    field: {
        minH: "34.5px",
        borderWidth: "1.5px",
    },
});

const sm = defineStyle({
    ...theme.components.Input.sizes.sm,
    height: "31.5px",
    borderRadius: "md",
});

const sizes = {
    sm: definePartsStyle({
        field: sm,
        addon: sm,
    }),
};

const inputTheme = defineMultiStyleConfig({
    baseStyle,
    sizes,
    defaultProps: { size: "sm" },
    variants: {
        ...theme.components.Input.variants,
        filterInput,
    },
});

export default inputTheme;
