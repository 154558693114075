import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
    color: "primary.700",
    fontWeight: "semibold",
});

const subscription = defineStyle({
    color: "blue.800",
    _hover: {
        textDecoration: "underline",
    },
    fontWeight: "normal",
});

const linkTheme = defineStyleConfig({
    baseStyle,
    variants: { subscription },
});

export default linkTheme;
