import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Menu: ComponentStyleConfig = {
    parts: ["button", "list", "item"],
    baseStyle: {
        button: {},
        list: {},
        item: {},
    },

    variants: {
        blue: {
            button: {
                bg: "detail.850",
                color: "black",
                fontSize: "xxs",
                fontWeight: "semibold",
                p: "2",
                rounded: "md",
            },
            list: {
                bg: "detail.850",
                color: "black",
                fontSize: "xxs",
                fontWeight: "semibold",
                p: "2",
                rounded: "md",
            },
            item: {
                display: "flex",
                alignItems: "baseline",
                flexGrow: 1,
            },
        },
        primary: {
            button: {
                h: 8,
                bg: "primary.1050",
                borderRadius: 1,
                borderEndRadius: 1,
                p: 2.5,
                _active: { bg: "primary.1050" },
            },
            list: {
                py: 2,
                px: 1.5,
                gap: 1.5,
                display: "flex",
                flexDirection: "column",
                bg: "white",
                border: "1px solid",
                borderColor: "background.850",
            },
            item: {
                color: "text.350",
                textTransform: "capitalize",
                borderRadius: 1,
                fontWeight: "semibold",
                minHeight: 8,
                fontSize: "xs",
                paddingX: 4,
                transition: "all 0s",
                paddingY: 1.5,
                _hover: {
                    bg: "background.600",
                    color: "background.150",
                },
            },
        },
    },

    defaultProps: {
        button: {
            bg: "none",
            color: "white",
            fontWeight: "normal",
            pt: "12px",
            mt: "-1px",
            w: "190px",
            _active: { bg: "white", textColor: "blackAlpha.600" },
            _focus: { outline: "none" },
            _hover: { bg: "none" },
        },
        list: {
            bg: "detail.800",
            border: "1px solid",
            borderColor: "detail.500",
            color: "black",
            fontWeight: "semibold",
            p: "2",
            rounded: "md",
        },
    },
};

export default Menu;
