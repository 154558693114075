import { alertAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle(props => {
    const { colorScheme } = props;

    return {
        title: {
            color: `${colorScheme}.500`,
        },
        description: {
            color: `${colorScheme}.500`,
        },
        container: {
            p: "2",
            rounded: "md",
            alignItems: "start",
            bg: `${colorScheme}.50`,
        },
    };
});

const alertTheme = defineMultiStyleConfig({
    baseStyle,
});

export default alertTheme;
