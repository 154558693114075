import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const todoVariant = definePartsStyle({
    container: {
        fontWeight: 700,
        fontSize: "md",
    },
    control: {
        borderColor: "todo.500",
        _checked: {
            borderColor: "todo.600",
            backgroundColor: "todo.600",
            _hover: {
                borderColor: "todo.600",
                backgroundColor: "todo.600",
            },
        },
    },
});

export const checkboxTheme = defineMultiStyleConfig({
    variants: {
        todo: todoVariant,
    },
});

export default checkboxTheme;
