import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { defineStyle, keyframes } from "@chakra-ui/react";

const helpers = createMultiStyleConfigHelpers(["container", "base", "ping"]);

const pulse = keyframes`
75%, 100% {
    transform: scale(2);
    opacity: 0;
  }`;

/* base style */
const baseStyle = defineStyle({
    container: {
        position: "relative",
        display: "flex",
    },
    ping: {
        w: "full",
        h: "full",
        position: "absolute",
        display: "inline-flex",
        opacity: "75%",
        rounded: "full",
        animation: `${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
    },
    base: {
        rounded: "full",
    },
});

/* variants */
const active = defineStyle({
    container: {},
    ping: { bg: "primary.400" },
    base: { bg: "primary.500" },
});

/* sizes */

const xs = defineStyle({
    container: { boxSize: "2" },
    base: { boxSize: "2" },
});

const sm = defineStyle({
    container: { boxSize: "3" },
    base: { boxSize: "3" },
});

const md = defineStyle({
    container: { boxSize: "4" },
    base: { boxSize: "4" },
});

const lg = defineStyle({
    container: { boxSize: "5" },
    base: { boxSize: "5" },
});

const pulseTheme = helpers.defineMultiStyleConfig({
    baseStyle,

    variants: {
        active,
    },

    sizes: {
        xs,
        sm,
        md,
        lg,
    },

    defaultProps: {
        size: "sm",
        variant: "active",
    },
});

export default pulseTheme;
