import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(selectAnatomy.keys);

const lightBlue = definePartsStyle({
    field: {
        bg: "blue.50",
        border: "1px solid",
        borderColor: "blue.500",
        width: "full",
        color: "blue.500",
        fontSize: "md",
        rounded: "md",
    },
    icon: {
        color: "detail.500",
    },
});

export const selectTheme = defineMultiStyleConfig({
    variants: { lightBlue },
});

export default selectTheme;
