import { TENANT_THEME } from "@constants/common";
import type { ServerBootstrap } from "@interfaces/common";
import { getBootstrapByTenant } from "@lib/api/theming";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useBootstrap = () => {
    const router = useRouter();

    const isTenant = useMemo(() => !!router.query?.site, [router.query?.site]);

    const query = useQuery<ServerBootstrap>(
        [TENANT_THEME],
        () => getBootstrapByTenant(),
        {
            refetchOnWindowFocus: false,
            enabled: isTenant,
        }
    );

    return { ...query, isReady: router?.isReady };
};
