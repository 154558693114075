const FormLabel = {
    baseStyle: {
        display: "flex",
        fontWeight: "medium",
        color: "text.250",
        fontSize: "xs",
        marginBottom: "px",
        letterSpacing: "0.5",
    },
};

export default FormLabel;
