import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const square = definePartsStyle({
    container: {
        rounded: "none",
        w: "9",
        h: "6",
        img: {
            rounded: "none",
        },
    },
});

const roundedSquare = definePartsStyle({
    container: {
        rounded: "md",
    },
});

const avatarTheme = defineMultiStyleConfig({
    variants: { square, roundedSquare },
});

export default avatarTheme;
