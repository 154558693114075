import { createContext } from "react";

export interface FormContextProps<T> {
    value?: T;
    isLoading?: boolean;
    isError?: boolean;
    error?: string;
    isSaveDraft?: boolean;
    isUpdateData?: boolean;
    // Methods
    setFormValues: (values: Object) => void;
    setIsLoading: (isLoading: boolean) => void;
    setIsError: (isError: boolean) => void;
    setError: (error: string) => void;
    setIsSaveDraft: (isSaveDraft: boolean) => void;
    setIsUpdateData: (isUpdateData: boolean) => void;
}

export const FormContext = createContext<FormContextProps<unknown>>(null);
