import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(parts.keys);

const service = definePartsStyle({
    header: {
        color: "primary.700",
        fontSize: "3xl",
        borderBottomWidth: "1px",
    },
});

const documents = definePartsStyle({
    header: {
        color: "primary.700",
        fontSize: "3xl",
        borderBottomWidth: "1px",
    },
    dialog: {
        zIndex: "0",
    },
});

const remittance = definePartsStyle({
    dialog: {
        bg: "background.50",
    },
});

const drawerTheme = defineMultiStyleConfig({
    variants: {
        service,
        documents,
        remittance,
    },
});

export default drawerTheme;
