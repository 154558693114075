import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Tabs: ComponentStyleConfig = {
    parts: ["root", "tab", "tablist", "tabpanel", "tabpanels", "indicator"],
    baseStyle: {
        root: {},
        tab: {},
        tablist: {},
        tabpanel: {},
        tabpanels: {},
        indicator: {},
    },

    variants: {
        "solid-rounded": {
            root: {},
            tab: {
                bg: "primary.50",
                h: 9,
                rounded: "md",
                fontWeight: "semibold",
                letterSpacing: 0.7,
                maxW: "72",
                color: "gray.700",
                textAlign: "left",
                _selected: {
                    bg: "primary.500",
                    color: "white",
                },
                _disabled: {
                    cursor: "not-allowed",
                    opacity: "0.6",
                },
                wordWrap: "break-word",
                whiteSpace: "normal",
            },
            tablist: {
                gap: "4",
                overflowX: "auto",
            },
            indicator: {
                bg: "red",
            },
        },
    },
    defaultProps: {
        variant: "solid-rounded",
    },
};

export default Tabs;
