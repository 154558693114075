import { getConnector, postConnector } from "@lib/axios";
import axios, { AxiosResponse } from "axios";
import { ResetPasswordPayload } from "interfaces/auth";
import { signOut } from "next-auth/react";
import { keysToSnakeCase } from "@utils/toCaseStyle";
const apiVersion = process.env.NEXT_PUBLIC_API_VERSION;

export const getCookiesFromResponse = (res: AxiosResponse) => {
    return res.headers["set-cookie"]?.reduce(
        (cookies, header) => `${cookies}${header.split(";")[0]}; `,
        ""
    );
};

export const mergeCookies = (arr1: string[], arr2: string[]): string[] => {
    const merged: Record<string, string> = {};
    arr1.forEach((cookie: string) => {
        const name = cookie.split("=")[0];
        merged[name] = cookie;
    });
    arr2.forEach((cookie: string) => {
        const name = cookie.split("=")[0];
        merged[name] = cookie;
    });
    return Object.values(merged);
};

export const getTokenFromResponse = (res: AxiosResponse) => {
    return decodeURIComponent(
        res.headers?.["set-cookie"]?.[0]
            ?.split(";")?.[0]
            .replace("XSRF-TOKEN=", "") ?? ""
    );
};

export const getTokenFromString = (cookies: string) => {
    return decodeURIComponent(
        cookies
            ?.split(";")
            ?.find(cookie => cookie.includes("XSRF-TOKEN="))
            ?.replace("XSRF-TOKEN=", "") ?? ""
    );
};

export const makeRequest = async ({
    method = "get",
    url,
    payload,
    cookies,
    host,
}: {
    method: "get" | "post";
    url: string;
    payload?: Record<string, string | undefined>;
    cookies?: string;
    host?: string;
}) => {
    // set the backend url depending on the host
    const backendUrl = host
        ? process.env.NEXT_TENANT_URL
        : process.env.NEXT_BACKEND_URL;

    // set the referer depending on the tenant when the host exists
    const referer = host ?? backendUrl;

    const token = cookies ? getTokenFromString(cookies) : null;

    const res = await axios.request({
        method,
        url: `${backendUrl}${url}`,
        data: payload,
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            "Content-Type": "application/json",
            "accept-encoding": "*",
            Cookie: cookies,
            "x-xsrf-token": token,
            referer,
        },
        withCredentials: true,
    });

    return res;
};

export const csrf = async () => {
    await getConnector(`/sanctum/csrf-cookie`);
};

export const getCurrentUser = async () => {
    const {
        data: { data },
    } = await getConnector(`/hermes/api/${apiVersion}/user`);

    return data;
};

export const customLogout = async () => {
    try {
        await postConnector(`/hermes/api/logout`);
        await signOut();
    } catch (error) {
        throw new Error(`logout error, ${error}`);
    }
};

export const forgotPassword = async ({ email }) => {
    await csrf();

    try {
        const response = await postConnector("/hermes/api/forgot-password", {
            email,
        });
        return { status: response.data.status, error: null };
    } catch (error) {
        return {
            status: null,
            error: Object.values(error.response.data.errors).flat(),
        };
    }
};

export const resetPassword = async (body: ResetPasswordPayload) => {
    await csrf();

    const payload = keysToSnakeCase(body);

    const response = await postConnector("/hermes/api/reset-password", payload);

    return response;
};
