import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { editableAnatomy } from "@chakra-ui/anatomy";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(editableAnatomy.keys);
/* base style */
const baseStyle = definePartsStyle({
    preview: {
        mx: "2",
        my: "auto",
    },
});

/* variants */
const invertedVariant = definePartsStyle({
    input: {
        borderRadius: 0,
        border: "none",
        boxShadow: "none",
        py: 0,
        px: 1,
        my: 0,
        _focus: {
            boxShadow: "none",
        },
    },
    textarea: {
        borderRadius: 0,
        border: "none",
        boxShadow: "none",
        py: 0,
        my: 0,
        _focus: {
            boxShadow: "none",
        },
    },
    preview: {
        px: 2,
    },
});

const todoVariant = definePartsStyle({
    preview: {
        margin: 0,
        paddingLeft: "18px",
        width: "full",
        textAlign: "left",
        fontSize: "md",
        lineHeight: "24px",
        fontWeight: 400,
    },
    textarea: {
        _focusVisible: {
            boxShadow: "none",
        },
        marginLeft: "18px",
        width: "calc(100% - 18px)",
        p: 3,
        rows: 4,
        resize: "none",
        color: "text.1700",
        borderWidth: 1,
        borderRadius: "4px",
        borderColor: "detail.1100",
    },
});

const editableTheme = defineMultiStyleConfig({
    baseStyle,
    variants: {
        inverted: invertedVariant,
        todo: todoVariant,
    },
});

export default editableTheme;
