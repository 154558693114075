import { useContext } from "react";
import { FormContext, FormContextProps } from "./FormContext";
import { FormProvider } from "./FormProvider";

const useFormContext = <T extends unknown>() => {
    const context = useContext(FormContext);

    if (!context) {
        throw new Error("useContextForm must be used within a FormProvider");
    }

    return context as FormContextProps<T>;
};

export { FormProvider, useFormContext };
