import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, theme } from "@chakra-ui/react";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    field: {
        textAlign: "right",
    },
});

const primary = definePartsStyle(props => ({
    field: {
        ...theme.components.NumberInput.variants.outline(props).field,
        bg: "white",
    },
}));

const numberInputTheme = defineMultiStyleConfig({
    baseStyle,
    variants: {
        ...theme.components.NumberInput.variants,
        primary,
    },
    defaultProps: {
        variant: "primary",
        size: "md",
    },
});

export default numberInputTheme;
