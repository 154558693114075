import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
    container: {
        px: 4,
        py: 3,
    },
    body: {
        w: "full",
        display: "flex",
        flexDirection: "column",
        p: "0",
    },
});

const cardTheme = defineMultiStyleConfig({
    baseStyle,
});

export default cardTheme;
