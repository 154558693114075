import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
    header: {
        fontSize: "md",
        pb: "2",
    },
    content: {
        mx: "4",
    },
    closeButton: {
        top: "-2",
        right: "-2",
        bg: "white",
        boxShadow: "md",
        _hover: {
            bg: "white",
            transform: "translate(-0.25rem, 0.25rem)",
        },
    },
});

const tenant = definePartsStyle({
    dialog: {
        w: "861px",
        h: "230px",
        bg: "white",
        marginTop: "5rem",
        borderRadius: "none",
        borderTop: "2px solid",
        borderColor: "primary.300",
        boxShadow: "sm",
    },
});

const Modal = defineMultiStyleConfig({
    baseStyle,
    variants: { tenant },
});

export default Modal;
