import { cleanObject } from "@utils/cleanObject";
import Axios, { AxiosError } from "axios";
import { customLogout } from "./api/auth";

const axios = Axios.create({
    baseURL: "",
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
    },
    withCredentials: true,
});

export default axios;

// Add an interceptor to handle 401 responses
axios.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
            // Handle 401 error and logout the user
            customLogout();
        }
        return Promise.reject(error);
    }
);

export const getConnector = <T = any>(
    path: string,
    params?: Filters,
    headers?: object
) => {
    params = params ? cleanObject(params) : {};

    return axios.get<T>(path, { params, headers });
};

export const postConnector = (path: string, data = {}, headers?: {}) => {
    return axios.post(path, data, { headers });
};

export const patchConnector = (path: string, data = {}) => {
    return axios.patch(path, data);
};

export const putConnector = (path: string, data = {}) => {
    return axios.put(path, data);
};

export const deleteConnector = (path: string, data = {}) => {
    return axios.delete(path, { data });
};
