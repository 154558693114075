import { Global } from "@emotion/react";

const Fonts = () => (
    <Global
        styles={`
                /* inter light */
                @font-face {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 300;
                    font-display: swap;
                    src: url('/fonts/inter/inter-300.woff') format('woff'), url('/fonts/inter/inter-300.ttf') format('truetype');
                }

                /* inter regular */
                @font-face {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-display: swap;
                    src: url('/fonts/inter/inter-400.woff') format('woff'), url('/fonts/inter/inter-400.ttf') format('truetype');
                }

                /* inter medium */
                @font-face {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-display: swap;
                    src: url('/fonts/inter/inter-500.woff') format('woff'), url('/fonts/inter/inter-500.ttf') format('truetype');
                }

                /* inter semibold */
                @font-face {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-display: swap;
                    src: url('/fonts/inter/inter-600.woff') format('woff'), url('/fonts/inter/inter-600.ttf') format('truetype');
                }

                /* inter bold */
                @font-face {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-display: swap;
                    src: url('/fonts/inter/inter-700.woff') format('woff'), url('/fonts/inter/inter-700.ttf') format('truetype');
                }
  `}
    />
);

export default Fonts;
