import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Modal: ComponentStyleConfig = {
    parts: ["container", "label", "helpText", "number", "icon"],
    baseStyle: {},
    variants: {
        card: {
            container: {
                rounded: "lg",
                bg: "white",
                p: "6",
                shadow: "md",
            },
            label: {
                color: "gray.500",
                fontSize: "sm",
                mb: "2",
            },
            number: {
                fontWeight: "bold",
            },
        },
    },
};

export default Modal;
