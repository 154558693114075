import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

const {
    definePartsStyle,
    defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(tagAnatomy.keys);

const status = definePartsStyle({
    container: {
        px: "3",
        py: "2",
        bg: "white",
        borderWidth: "1px",
        borderColor: "gray.200",
        color: "green.500",
        textTransform: "capitalize",
    },
});

const light = definePartsStyle(props => {
    const { colorScheme } = props;
    return {
        container: {
            px: "3",
            py: "2",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.200",
            color: `${colorScheme}.500`,
            textTransform: "capitalize",
            background: `${colorScheme}.50`,
        },
    };
});

const committee = definePartsStyle(p => ({
    ...theme.components.Tag.variants.subtle(p),
    container: {
        ...theme.components.Tag.variants.subtle(p).container,
        textTransform: "none",
        fontWeight: 600,
        px: 3,
        py: 1,
        w: "fit-content",
        borderRadius: 5,
    },
}));

const tag = defineMultiStyleConfig({
    variants: {
        status,
        light,
        committee,
    },
});

export default tag;
