import { TenantThemeState } from "./TenantThemeProvider";

type ThemeActionType = {
    type: "[THEME] - Set Tenant Theme";
    payload: any;
};

export const tenantThemeReducer = (
    state: TenantThemeState | undefined,
    action: ThemeActionType
): TenantThemeState => {
    switch (action.type) {
        case "[THEME] - Set Tenant Theme":
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};
