export const stringToCamel = (string: string) => {
    return string.replace(/([-_][a-z])/gi, $1 => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
    });
};

export function wordToCamelCase(string: string) {
    return string.toLowerCase().replace(/([-_\s][a-z])/gi, $1 => {
        return $1
            .toUpperCase()
            .replace("-", "")
            .replace("_", "")
            .replace(/\s/, "");
    });
}

export const stringToSnakeCase = (str: string): string => {
    return str
        .replace(/[-\s]+|([A-Z])/g, (match, group1, offset) => {
            if (match === "-" || match === " ") return "_";
            return (offset > 0 ? "_" : "") + group1.toLowerCase();
        })
        .replace(/^_+|_+$/g, "")
        .toLowerCase();
};

export const keysToCamelCase = (object: Object) => {
    const newObject: any = {};

    Object.keys(object).forEach(k => {
        newObject[stringToCamel(k)] = object[k];
    });

    return newObject;
};

export const keysToSnakeCase = (object: Object) => {
    const newObject: any = {};

    Object.keys(object).forEach(k => {
        newObject[stringToSnakeCase(k)] = object[k];
    });

    return newObject;
};
