import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

/* base style */
const baseStyle = defineStyle({
    parts: ["button", "iconbutton"],
    fontWeight: "medium",
    fontSize: "sm",
    borderRadius: "lg",
    paddingY: "2",
    h: "auto",
});

/* variants */
const solid = defineStyle(props => {
    const { colorScheme } = props;

    return {
        bg: `${colorScheme}.500`,
        color: "white",
        border: "1",
        px: "6",
        borderColor: `${colorScheme}.500`,
        borderRadius: "lg",

        fontSize: "sm",
        _hover: {
            bg: `${colorScheme}.500`,
            opacity: "0.8",
        },
        ":hover[disabled]:hover": {
            bg: `${colorScheme}.500`,
        },
        _disabled: {
            opacity: "0.4",
            cursor: "not-allowed",
            _hover: { opacity: "0.4" },
        },
    };
});

const solidLight = defineStyle(props => {
    const { colorScheme } = props;

    return {
        bg: `${colorScheme}.100`,
        color: `${colorScheme}.600`,
        border: "1",
        px: "6",
        borderColor: `${colorScheme}.50`,
        borderRadius: "lg",

        fontSize: "sm",
        _hover: {
            bg: `${colorScheme}.200`,
            opacity: "0.8",
        },
        ":hover[disabled]:hover": {
            bg: `${colorScheme}.200`,
        },
        _disabled: {
            opacity: "0.4",
            cursor: "not-allowed",
        },
    };
});

const light = defineStyle(props => {
    const { colorScheme } = props;

    return {
        bg: `${colorScheme}.50`,
        color: `${colorScheme}.700`,
        border: "1",
        px: "6",
        borderColor: `${colorScheme}.50`,
        borderRadius: "lg",

        fontSize: "sm",
        _hover: {
            bg: `${colorScheme}.100`,
            opacity: "0.8",
        },
        ":hover[disabled]:hover": {
            opacity: "0.5",
        },
        _disabled: {
            opacity: "0.4",
            cursor: "not-allowed",
        },
    };
});

const rounded = defineStyle({
    bg: "primary.500",
    color: "white",
    padding: "2",
    borderRadius: "full",
    fontWeight: "bold",
    transition: "all .05s ease-in-out",
    _hover: {
        bg: "primary.500",
        opacity: "0.8",
    },
    ":hover[disabled]:hover": {
        bg: "primary.500",
    },
});

const outline = defineStyle({
    px: "6",
    border: "1px",
    borderRadius: "lg",
    fontSize: "sm",
});

const outlinedLight = defineStyle({
    px: "6",
    border: "1px",
    borderColor: "primary.300",
    py: "4",
    borderRadius: "md",
    fontSize: "sm",
    bg: "white",
});

const disabledOutline = defineStyle({
    height: "2.34rem",
    bg: "white",
    border: "1px",
    borderColor: "text.1100",
    borderRadius: "md",
    px: "5",
    color: "text.1100",
    fontSize: "sm",
    _hover: {
        bg: "text.1100",
        color: "white",
    },
});

const filter = defineStyle({
    height: "2.34rem",
    bg: "primary.1000",
    border: "1px",
    borderColor: "primary.1000",
    borderRadius: "3xl",
    px: "5",
    color: "black",
    fontSize: "sm",
    _hover: {
        bg: "primary.500",
        borderColor: "primary.500",
        color: "white",
    },
});

const filterTab = defineStyle({
    bg: "primary.100",
    color: "primary.800",
    border: "1",
    px: "6",
    borderColor: "primary.500",
    borderRadius: "lg",

    fontSize: "sm",
    _hover: {
        bg: "primary.50",
        opacity: "0.8",
    },
    ":hover[disabled]:hover": {
        bg: "primary.500",
    },
    _disabled: {
        opacity: "0.4",
        cursor: "not-allowed",
    },
});

const dark = defineStyle({
    bg: "primary.800",
    color: "white",
    border: "1",
    px: "6",
    borderColor: "primary.500",
    borderRadius: "lg",

    fontSize: "sm",
    _hover: {
        bg: "primary.500",
        opacity: "0.8",
    },
    ":hover[disabled]:hover": {
        bg: "primary.500",
    },
    _disabled: {
        opacity: "0.4",
        cursor: "not-allowed",
    },
});

const suscription = defineStyle({
    bg: "primary.700",
    color: "white",
    border: "1px solid",
    py: "2",
    px: "8",
    borderColor: "primary.100",
    borderRadius: "lg",
    opacity: "0.8",

    fontSize: "sm",
    _hover: {
        bg: "primary.500",
        opacity: "0.8",
    },
    ":hover[disabled]:hover": {
        bg: "primary.500",
    },
    _disabled: {
        opacity: "0.4",
        cursor: "not-allowed",
    },
});

const support = defineStyle({
    bg: "primary.700",
    color: "white",
    borderRadius: "lg",
    fontSize: "sm",
    _hover: {
        bg: "primary.500",
        opacity: "0.8",
    },
    ":hover[disabled]:hover": {
        bg: "primary.500",
    },
    _disabled: {
        opacity: "0.4",
        cursor: "not-allowed",
    },
});

const assign = defineStyle({
    height: "2.34rem",
    bg: "accent.50",
    color: "white",
    border: "1",
    px: "6",
    borderColor: "primary.200",
    borderRadius: "lg",

    fontSize: "sm",
    _hover: {
        bg: "primary.200",
        opacity: "0.8",
    },
    ":hover[disabled]:hover": {
        bg: "primary.300",
    },
    _disabled: {
        opacity: "0.4",
        cursor: "not-allowed",
    },
});

const floatingRight = defineStyle({
    position: "absolute",
    top: 2,
    right: 2,
    color: "gray.500",
});

const link = defineStyle({
    color: "primary.500",
});

const accent = defineStyle({
    bg: "cyan.500",
    color: "white",
    px: "6",
    borderRadius: "lg",

    fontSize: "sm",
    _hover: {
        opacity: "0.8",
    },
    ":hover[disabled]:hover": {
        bg: "cyan.300",
    },
    _disabled: {
        opacity: "0.4",
        cursor: "not-allowed",
    },
});

const ghostLight = {
    bg: "transparent",
    color: "white",
    px: "2",
    ":not([disabled]):hover": {
        color: "accent.450",
    },
    _disabled: {
        opacity: "0.4",
        cursor: "not-allowed",
    },
};

const todoCancel = defineStyle({
    borderRadius: "4px",
    padding: "2px, 8px, 2px, 8px",
    color: "text.50",
    background: "detail.950",
    fontSize: "sm",
    height: "34px",
});

const todoSave = defineStyle({
    borderRadius: "4px",
    padding: "2px, 8px, 2px, 8px",
    color: "white",
    background: "accent.1200",
    fontSize: "sm",
    height: "34px",
});

const saveLaterButton = defineStyle({
    border: "1px",
    bgColor: "gray.100",
    borderColor: "gray.200",
    letterSpacing: 0.5,
    _hover: { opacity: 0.5 },
    fontWeight: "medium",
    textColor: "black",
    paddingInline: 6,
});

const voteButton = defineStyle({
    ...solidLight,
    flex: 1,
    variant: solidLight,
    bg: "background.800",
    color: "primary.1050",
    py: 5,
    gap: 2,
    _hover: {
        bg: "background.600",
    },
});

const whiteMenuButton = defineStyle({
    bg: "white",
    color: "background.400",
    border: "1px solid",
    borderColor: "detail.950",
    _focus: {
        bg: "white",
    },
    _active: { bg: "white" },
    fontWeight: "medium",
    minH: 8,
    fontSize: "xs",
    borderRadius: "base",
    px: 2.5,
});

const primaryMenuButton = defineStyle({
    bg: "primary.1050",
    color: "white",
    borderRadius: "base",
    border: "1px solid",
    px: 2.5,
    borderColor: "primary.1050",
    fontSize: "xs",
    minH: 8,
    fontWeight: "medium",
    _active: { bg: "primary.1050" },
});

/* sizes */
const lg = defineStyle({
    py: "4",
});

const full = defineStyle({
    w: "full",
    py: "4",
});

const buttonTheme = defineStyleConfig({
    baseStyle,

    variants: {
        ...theme.components.Button.variants,
        solid,
        rounded,
        whiteMenuButton,
        primaryMenuButton,
        outline,
        disabledOutline,
        filter,
        filterTab,
        dark,
        assign,
        floatingRight,
        link,
        accent,
        suscription,
        support,
        outlinedLight,
        ghostLight,
        solidLight,
        todoCancel,
        todoSave,
        saveLaterButton,
        light,
        vote: voteButton,
    },

    sizes: {
        ...theme.components.Button.sizes,
        lg,
        full,
    },

    defaultProps: {
        size: "sm",
        variant: "solid",
        colorScheme: "primary",
    },
});

export default buttonTheme;
