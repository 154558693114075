const styles = {
    global: {
        ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
            overflow: "initial !important",
        },
        "html, body": {
            fontSize: "sm",
            color: "gray.600",
            background: "no-repeat padding-box",
            backgroundColor: "background.50",
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
        },
        a: {
            color: "Primary100",
        },
        h1: {
            fontSize: "xl",
            fontFamily: "heading",
            fontWeight: "bold",
            lineHeight: "54px",
            color: "gray.800",
        },
        h2: {
            fontSize: "24px",
            fontFamily: "heading",
            fontWeight: "normal",
            color: "gray.800",
        },
        h3: {
            fontSize: "xl",
            fontFamily: "heading",
            fontWeight: "bold",
            lineHeight: "37px",
            color: "gray.700",
        },
        h4: {
            fontSize: ["md", "lg"],
            fontFamily: "heading",
            fontWeight: "medium",
            lineHeight: "36px",
            color: "gray.700",
        },
        h5: {
            fontSize: "md",
            fontFamily: "heading",
            fontWeight: "medium",
            lineHeight: "23px",
            color: "#222222",
        },
        h6: {
            fontSize: "14px",
            fontFamily: "heading",
            fontWeight: "medium",
            lineHeight: "19px",
            color: "#222222",
        },
        p: {
            fontSize: "sm",
            lineHeight: "150%",
        },
        form: {
            width: "full",
        },

        ".datePickerStyle": {
            height: "18px",
            width: "100%",
            color: "gray.400",
            paddingLeft: "15px",
            borderRadius: "6px",
            border: "1px solid gray.400",
        },

        ".react-datepicker": {
            display: "flex !important",
            flexDirection: "column !important",
            justifyContent: "center !important",
            alignItems: "start !important",
            width: "100% !important",
            borderColor: "gray.200",
            rounded: "lg",
            borderRadius: "4px !important",
        },

        /* SCROLLBAR STYLE */
        "&::-webkit-scrollbar": {
            width: "16px",
        },

        "&::-webkit-scrollbar-track": {
            backgroundColor: `white`,
        },

        "&::-webkit-scrollbar-button": {
            display: "none",
        },

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: `rgba(0, 0, 0, 0.20)`,
            border: "4px solid white",
            borderRadius: "20px",
            width: "7px",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#9ca3af",
        },
        /* -------------- */

        ".react-datepicker-time__input": {
            pr: "6",
            w: "xs",
        },

        ".react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input": {
            w: "full",
        },

        ".react-datepicker-popper": {
            zIndex: 2000,
            "&[data-placement^=bottom]": {
                pt: "2",
            },
        },

        ".react-date-picker__wrapper": { border: "0 !important" },
        ".react-datepicker__month-container": {
            fontSize: "1rem !important",
        },

        ".react-datepicker__header": {
            textAlign: "center !important",
            backgroundColor: "white !important",
            borderBottom: "none",
            roundedTop: "lg",
            paddingTop: "8px !important",
            position: "relative !important",
        },

        ".react-datepicker__day-names": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            px: "4",
            pt: "2",
            fontSize: "xs",
        },

        ".react-datepicker__day-name": {
            color: "gray.400",
            fontWeight: "medium",
        },

        ".react-datepicker__triangle": {
            display: "none",
        },

        ".react-datepicker__day": {
            w: "12",
            borderRadius: "2",
            margin: "0px",
            textAlign: "center",
            lineHeight: "2.5em",
            transition: "all linear 100ms",
            m: "0",
            color: "gray.600",

            "&.react-datepicker__day--keyboard-selected": {
                background: "primary.100",
                rounded: "lg",
                color: "gray.800",
            },

            "&.react-datepicker__day--selected": {
                background: "primary.500",
                rounded: "lg",
                color: "white",
                fontWeight: "bold",
                "&.react-datepicker__day--range-start": {
                    background: "primary.500",
                    color: "white",
                    roundedLeft: "lg",
                    roundedRight: "none",
                },
            },

            "&.react-datepicker__day--range-end": {
                background: "primary.500",
                color: "white",
                roundedRight: "lg",
                roundedLeft: "none",
                fontWeight: "bold",
                "&:hover": {
                    background: "primary.500",
                },
            },

            "&.react-datepicker__day--in-selecting-range": {
                background: "gray.100",
                color: "gray.800",
                borderRadius: "none",
                "&:hover": {
                    roundedRight: "lg",
                },
                "&.react-datepicker__day--selected": {
                    background: "primary.100",
                    "&.react-datepicker__day--selecting-range-start": {
                        roundedLeft: "lg",
                    },
                },
            },

            "&:hover": {
                backgroundColor: "gray.200",
            },

            "&.react-datepicker__day--disabled": {
                color: "gray.300",
                "&:hover": {
                    bg: "none",
                },
            },
        },

        ".react-datepicker__year": {
            rounded: "lg",
            fontSize: "1rem !important",
            color: "gray.600",
            ".react-datepicker__year-text": {
                transition: "all linear 100ms",
                px: "2",
                py: "1",
                rounded: "lg",
                "&--today": {
                    color: "primary.500",
                    bg: "white",
                    fontWeight: "bold",
                },
                "&--selected, &--keyboard-selected": {
                    background: "primary.500",
                    color: "white",
                    fontWeight: "bold",
                },
                "&:hover": {
                    backgroundColor: "gray.200",
                },
            },
        },

        ".react-datepicker__day--in-range": {
            background: "gray.100",
            color: "gray.800",
        },

        ".react-datepicker__day--today": {
            color: "primary.500",
            bg: "white",
            fontWeight: "bold",
        },

        ".chakra-react-select__menu": {
            position: "relative",
            background: "white",
            color: "inherit",
            minWidth: "100%",
            borderRadius: "md",
            borderWidth: "1px",
            boxShadow: "sm",
            overflowY: "auto",
            zIndex: "99",
        },

        ".yearsMonthCalendar": {
            ".react-datepicker__triangle": {
                outline: "1px solid red !important",
                border: "1px solid red !important",
            },

            ".react-date-picker__wrapper": { border: "0 !important" },

            ".react-datepicker__month ": {
                "&.react-datepicker__monthPicker": {
                    border: "1px solid #EAECF2",
                    "border-radius": "6px",
                    w: "full",
                },
            },

            ".react-datepicker__month-text--keyboard-selected": {
                bg: "#18ACFF",
                "&:hover": {
                    bg: "#18ACFF",
                },
            },

            ".react-datepicker__month-container": {
                fontSize: "1.1rem !important",
                w: "full",
                border: "1px solid white !important",
                outline: "1px solid white !important",
                shadow: "1px solid white !important",
            },

            ".react-datepicker__month-text": {
                width: "145px !important",
                py: 1,
                my: "7px !important",
            },
        },
    },
};

export default styles;
