const FormErrorMessage = {
    baseStyle: {
        text: {
            mt: "px",
            fontSize: "xs",
        },
    },
};

export default FormErrorMessage;
