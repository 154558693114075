import { Box, Progress, VStack } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";

export const LoadingScreen = () => {
    return (
        <VStack h="screen" w="full" justify="center" spacing="10">
            <Box>
                <Image
                    alt="Hermes Logo"
                    src="/login-assets/hermes.svg"
                    priority
                    width="200"
                    height="200"
                />
            </Box>
            <Progress
                colorScheme="pink"
                w="full"
                maxW="xs"
                size="sm"
                isIndeterminate
                borderRadius="full"
            />
        </VStack>
    );
};
